import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Empty, Popconfirm} from 'antd'

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import {
  fetchAddressList,
  fetchDefaultAddress,
  updateAddressItem,
  removeAddressItem,
} from '@/store/modules/address.slice';

import MyAddressDetail from '@/components/MyAddressDetail'

const defaultProps = {
  className: '',
  layoutRestoreScrollState: () => {},
};

export default function MyAddress(props) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const addressState = useSelector(state => state.address)
  const rootClassName = commonState.isMobile ? 'my-address-mobile-container' : 'my-address-container'
  
  const {customerDetail, defaultAddress} = addressState

  const [isDetailPage, setIsDetailPage] = useState(false)
  const [detailPageEditItem, setDetailPageEditItem] = useState(null)
  
  const notDefaultAddressList = useMemo(() => {
    return customerDetail.filter(item => !item.IsDefault && item.CustomerDetail_id !== defaultAddress.CustomerDetail_id)
  }, [customerDetail, defaultAddress])
  const getAddressList = () => {
    dispatch(fetchAddressList())
  }
  const getDefaultAddress = () => {
    dispatch(fetchDefaultAddress())
  }
  
  useEffect(() => {
    getAddressList()
    getDefaultAddress()
  }, [])
  
  const handleAddNewAddressClick = () => {
    setDetailPageEditItem(null)
    setIsDetailPage(true)
  }
  const handleEditClick = item => {
    setDetailPageEditItem(item)
    setIsDetailPage(true)
  }

  useEffect(() => {
    options.layoutRestoreScrollState()
  }, [isDetailPage])
  
  const handleRemoveClick = item => {
    dispatch(removeAddressItem({customerDetailId: item.CustomerDetail_id}))
  }

  const handleSetDefault = item => {
    dispatch(updateAddressItem({
      address: {
        ...item,
        IsDefault: true
      },
      customerDetailId: item.CustomerDetail_id
    }))
  }
  const renderAddressItem = (item, index) => <div
    className={'my-address-list-item-container'}
    key={`address_list_${index}`}>
    <div className={'my-address-list-item-top-container'}>
      <div className={'my-address-list-item-top-left-container'}>
        {
          item.IsDefault &&
          <>
            <div className={'my-address-list-item-top-left-title'}>
              {t('Default Address')}
            </div>
          </>
        }
        {
          !item.IsDefault &&
          <>
            <div className={'my-address-list-item-top-left-title'}>
              {t('Address')} {index + 1}
            </div>
            <div
              className={'my-address-list-item-top-left-set-default'}
              onClick={() => handleSetDefault(item)}>
              {t('Set as default')}
            </div>
          </>
        }
      </div>
      <div className={'my-address-list-item-top-op-container'}>
        <div
          className={'my-address-list-item-top-op-item-container'}
          onClick={() => handleEditClick(item)}>
          {t('Edit')}
        </div>
        <Popconfirm
          title={t('Warning')}
          description={t('Remove this address from the list?')}
          okText={t('Yes')}
          cancelText={t('No')}
          onConfirm={() => handleRemoveClick(item)}
        >
          <div className={'my-address-list-item-top-op-item-container'}>
            {t('Remove')}
          </div>
        </Popconfirm>
      </div>
    </div>
    <div className={'my-address-list-item-address-1'}>{item.Street}</div>
    <div className={'my-address-list-item-address-2'}>
      {
        item.City &&
        <span>
          {item.City}
        </span>
      }
      {
        item.State &&
        <span>
          , {item.State}
        </span>
      }
      {
        item.Zip &&
        <span>
          , {item.Zip}
        </span>
      }
    </div>
  </div>
  
  const handleDetailBackClick = () => {
    setIsDetailPage(false)
  }
  
  const renderDefaultPage = () => <div
    className={'my-address-default-page-container'}>
    {
      !customerDetail.length &&
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
    <div className={'my-address-list-container'}>
      {renderAddressItem(defaultAddress, -1)}
      {notDefaultAddressList.map((item, index) => renderAddressItem(item, index))}
    </div>
    <div
      className={'my-address-btn'}
      onClick={handleAddNewAddressClick}>
      {t('Add New Address')}
    </div>
  </div>
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      {
        !isDetailPage && renderDefaultPage()
      }
      {
        isDetailPage &&
        <MyAddressDetail
          onBack={handleDetailBackClick}
          editInfo={detailPageEditItem}
          showArrow={true}
        />
      }
    </div>
  )
}
