import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Empty, message, Checkbox} from 'antd';
import {cloneDeep} from 'lodash'

import './index.less'
import './mobile.less'

import {checkLogin, getUsingClassName} from '@/common';
import {getFavoriteList, removeProductFromFavoriteList, addProductToFavoriteList} from '@/api/req-api';
import {addProductToCart} from '@/store/modules/cart.slice';

import ProductItem2 from '@/components/ProductItem2'

const defaultProps = {
  className: '',
};

export default function MyList(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const options = Object.assign({}, defaultProps, props);
  const [isOutOfStock, setIsOutOfStock] = useState(true);
  const commonState = useSelector(state => state.common)
  const isLogged = commonState.isLogged
  const rootClassName = commonState.isMobile ? 'my-list-mobile-container' : 'my-list-container'

  const [list, setList] = useState([])
  const isShowMore = useMemo(() => list.length > 6, [list.length])
  const [checkAll, setCheckAll] = useState(false)
  const checkedListRef = useRef([])
  
  useEffect(() => {
    getFavoriteList().then(res => {
      const formatData = res.data.map(item => {
        return {
          ...item,
          quantity: item.FavourateQuantity,
          checked: false,
        }
      })
      setList(formatData)
    })
  }, [])

  const handleProductItemClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    navigate(path);
  }
  const handleProductItemBtnClick = item => {
    dispatch(addProductToCart({item, quantity: item.quantity}))
  }
  const handleBeforeAddToCart = item => {
    checkLogin(
      navigate,
      isLogged,
      location,
      () => handleProductItemBtnClick(item)
    )
  }

  const handleBeforeAddToCarts = items => {
    checkLogin(
      navigate,
      isLogged,
      location,
      () => handleProductItemBtnClick(items)
    )
  }

  const handleRemoveProductFromFavoriteList = item => {
    const newList = cloneDeep(list)
    const idx = newList.findIndex(ite => ite.Product_id ===  item.Product_id)
    if (idx < 0) {
      return
    }
    removeProductFromFavoriteList(item).then(() => {
      message.success(t('Cancel favorite successfully'))
      newList.splice(idx, 1)
      setList(newList)
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = item => {
    handleBeforeChangeFavoriteStatus(item, () => handleRemoveProductFromFavoriteList(item))
  }
  
  const handleQuantityChange = (item, quantity) => {
    const newList = cloneDeep(list)
    const idx = newList.findIndex(ite => ite.Product_id ===  item.Product_id)
    if (idx < 0) {
      return
    }
    newList.splice(idx, 1, {...item, quantity})
    setList(newList)
    // 收藏数量改变 调用接口
    if(quantity >= 1) {
      addProductToFavoriteList(item, quantity);
    }
  }
  
  const handleCheckChange = (item) => { // 单个商品 选中状态改变
    const newList = cloneDeep(list);
    const idx = newList.findIndex(ite => ite.Product_id ===  item.Product_id)
    if (idx < 0) {
      return
    }
    let changeItem = {...item, checked:!item.checked};
    newList.splice(idx, 1, changeItem)
    setList(newList)
    // 更改选中状态
    const checkedIdx = checkedListRef.current.findIndex(ite => ite.Product_id ===  item.Product_id)
    if(checkedIdx < 0 && changeItem.checked) {
      checkedListRef.current.push(changeItem)
      checkCheckedOutOfStock();
      return
    }
    if(checkedIdx >= 0 && !changeItem.checked) {
      checkedListRef.current.splice(checkedIdx, 1)
      if(checkedListRef.current.length == 0) {
        setIsOutOfStock(true);
        setCheckAll(false)
        return
      }
      checkCheckedOutOfStock();
    }
  }

  const checkCheckedOutOfStock = () => {
    let outOfStockId = checkedListRef.current.findIndex(item => (!item.IsOverSaleable && item.StockLevel <= 0))
    if(outOfStockId < 0) {
      setIsOutOfStock(false);
      setCheckAll(true)
    } else {
      setIsOutOfStock(true);
      setCheckAll(false)
    }
  }
  
  const renderItem = (item, index) => {
    return <ProductItem2
      onProductItemClick={() => handleProductItemClick(item)}
      img={item.Thumbnail}
      des={item.Name}
      desChinese={item.Description}
      quantity={item.quantity}
      price={item.Price}
      isShowPrice={isLogged}
      isFormatPriceToMoney={true}
      isFavorite={true}
      isCheck={true}
      checked={item.checked}
      onCheckChange={() => handleCheckChange(item)}
      onBtnClick={() => handleBeforeAddToCart(item)}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      onQuantityChange={quantity => handleQuantityChange(item, quantity)}
      key={`my_list_${index}`}
      isOverSaleable={item.IsOverSaleable}
      stock={item.StockLevel}
      isPromotion={item.IsPromotion}
      productType={item.ProductType}
      measureUnit={item.MeasureUnit}
      packageType={item.PackageType}
    />
  }
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  const handleCheckAll = (e) => {// 全选
    setCheckAll(e.target.checked)
    const newList = cloneDeep(list);
    let isOverSales = false;
    newList.map(item => {
      if(!item.IsOverSaleable && item.StockLevel <= 0) { // 是否存在无库存(非超售)商品
        isOverSales = true;
      }
      item.checked = e.target.checked;
    })
    if(newList.length == 0) { // 列表为空时,不能点击添加购物车
      isOverSales = true;
    }
    if(!e.target.checked){
      isOverSales = true;
    }
    setIsOutOfStock(isOverSales)
    setList(newList);
    if(e.target.checked) { // 更新选中的商品列表
      checkedListRef.current = newList;
    } else {
      checkedListRef.current = [];
    }
  }

  const handleBtnClick = () => { // todo 多选添加购物车
    if(!isOutOfStock){
      dispatch(addProductToCart({item: checkedListRef.current}))
    }
  }

  return (
    <div className={className}>
      {
        !list.length &&
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      {list.length ? <div className='select_row'>
        <Checkbox checked={checkAll} onChange={handleCheckAll}>{t('Select All')}</Checkbox>
        <div className={isOutOfStock ? 'product-item-2-btn-disabled' : 'product-item-2-btn'} onClick={handleBtnClick}>
              {t('Add to cart')}
        </div>
      </div> : null}
      <div >
        {list.map((item, index) => renderItem(item, index))}
      </div>
      
      {
        isShowMore &&
        <div className={'my-list-show-more-btn-container'}>
          {t('Click to show more')}
        </div>
      }
    </div>
  )
}
