import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import facebookOutlined from '@/assets/img/headers/facebook.png';
import linkedinOutlined from '@/assets/img/headers/in.png';
import instagramOutlined from '@/assets/img/headers/instagram.png';
import {useTranslation} from 'react-i18next';

import './index.less'
import './mobile.less'

import Header from '@/components/Headers'
import Footer from '@/components/Footers'

import {getUsingClassName} from '@/common';

const defaultProps = {
  className: '',
  isShowFooterTop: true,
  isDetailPage: false,
  myCartShow: false,
  detailPageTitle: '',
  detailPageChineseTitle: '',
  onHeaderLeftDetailPageClick: undefined,
  headerLogoutIsGoHome: false,
  pathnameChangeIsRestoreScrollState: true,
  isShowHeaderRight: true,
  showFooter: true,
};

const Layout = forwardRef(function (props, ref) {
  const {t} = useTranslation()
  const options = Object.assign({}, defaultProps, props);

  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'layout-mobile-container' : 'layout-container'
  const greyHeaderClassName = commonState.isMobile ? 'layout-mobile-container-notDetail-top' : 'layout-container-notDetail-top'

  const restoreScrollState = (top = 0) => {
    window.scroll({
      top: top,
      left: 0,
      // behavior: 'smooth'
    });
  }
  
  const scrollIntoView = (el, options) => {
    el.scrollIntoView(options)
  }
  
  useEffect(() => {
    options.pathnameChangeIsRestoreScrollState && restoreScrollState()
  }, [location.pathname])
  
  useImperativeHandle(ref, () => ({
    restoreScrollState,
    scrollIntoView
  }))
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  return (
    <div>
        {
          !options.isDetailPage && <div className={greyHeaderClassName}>
          <div className='notDetail-top-img-style'>
           {/* <img src={facebookOutlined} className='notDetail-top-icon'/>
           <img src={linkedinOutlined} className='notDetail-top-icon'/>
           <img src={instagramOutlined} className='notDetail-top-icon'/> */}
          </div>
           <div className='not-detail-top-callUs'>{t('Call Us Now')}: (626) 575-1500 {t('or')} (626) 350-3900 </div>
         </div>
        } 
      
      <div className={className}>
        <Header
          className={ options.isDetailPage ? 'layout-header-container' : 'layout-header-container-notDetail'}
          logoutIsGoHome={options.headerLogoutIsGoHome}
          isDetailPage={options.isDetailPage}
          myCartShow={options.myCartShow}
          detailPageTitle={options.detailPageTitle}
          detailPageChineseTitle={options.detailPageChineseTitle}
          onLeftDetailPageClick={options.onHeaderLeftDetailPageClick}
          isShowHeaderRight={options.isShowHeaderRight}
        />
        <div className={'app-content-container'}>
          {options.children}
        </div>
        {options.showFooter && <Footer isShowFooterTop={options.isShowFooterTop} />}
      </div>
    </div>
  )
})

export default Layout
