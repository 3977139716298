import React, {useRef} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import ProductsContent from '@/components/ProductsContent'
import bannerImg from '@/assets/img/ourProducts/banner.jpg'
export default function OurProducts() {
  const {t} = useTranslation()
  const commonState = useSelector(state => state.common)
  const cartState = useSelector(state => state.cart)
  const rootClassName = commonState.isMobile ? 'our-products-mobile-container' : 'our-products-container'
  const {orderCartProductList} = cartState
  
  const productsContentRef = useRef(null)
  const handlePageScroll = event => {
    productsContentRef.current?.handlePageScroll(event)
  }
  return (
    <Layout pathnameChangeIsRestoreScrollState={false} showFooter={false}>
      <div className={rootClassName} onScroll={handlePageScroll}>
        <ContentHeader
          className={'our-products-header-container'}
          des={t('Product listing')}
          title={<span className='headerTitle'>{t('Explore Our Exceptional Product Collection: Delivering Value to Your Life!')}</span>}
          banner={null}
        />
        <ProductsContent
          ref={productsContentRef}
          rootPath={'/ourProducts'}
          scrollTop={210}
          isEditOrder={false}
          cartProductList={orderCartProductList}
        />
      </div>
    </Layout>
  )
}
