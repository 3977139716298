import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'

import {ConfigProvider} from 'antd'

import App from './App';

import {store} from '@/store'
import '@/i18next'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#3F72AF',
        },
      }}>
      <App />
    </ConfigProvider>
  </Provider>
);
