import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import WechatPopover from '@/components/WechatPopover'
import logoImg from '@/assets/img/home/bannerTitle.png'
import wechatImg from '@/assets/img/icon/wechat.png'
import instagramImg from '@/assets/img/icon/instagram.png'
import facebookImg from '@/assets/img/icon/facebook.png'
import {FACEBOOK_LINK} from '@/constants';

const defaultProps = {
  isShowFooterTop: true,
};

export default function Footer(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'footer-mobile-container' : 'footer-container'
  
  const handleContactUsClick = () => {
    const path = '/contactUs'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  
  return (
    <div className={rootClassName} id={'page-footer'}>
      {
        options.isShowFooterTop &&
        <div className={'footer-top-container'}>
          <div className={'footer-top-content-container'}>
            <div className={'footer-top-content-left-container'}>
              <div className={'footer-top-content-left-logo-container'}>
                <img src={logoImg} alt="logo" />
              </div>
              {/* <div className={'footer-top-content-left-des'}>
                Olong Trading Inc, located at 31 Ave. in Faraching, (next to Highway 678, near the university site, next to the DMV supervision station), reopened on (9/18). We specialize in providing hundreds of high-quality goods such as seafood, vegetables, meat, dry goods, seasonings and lunch boxes.
              </div> */}
              <div className={'footer-top-content-right-working-hours-container'}>
                <div className={'footer-top-content-title'}>{t('WORKING HOURS')}</div>
                  <div>
                    <div className={'footer-top-office-business-hour-item-container'}>{t('OFFICE BUSINESS HOURS')}:</div>
                    <ul>
                      <li>
                        <div className={'footer-top-content-right-working-hours-item-container'}>
                          {t('Mon - Fri')} {t('8:00 AM - 5:00 PM')}
                        </div>
                      </li>
                    </ul>
                  </div>
                <div>
                  <div style={{marginTop: '-17px'}}>
                    <div className={'footer-top-office-business-hour-item-container'}>{t('WHOLESALE MARKET HOURS')}:</div>
                    <ul>
                      <li>
                        <div className={'footer-top-content-right-working-hours-item-container'} style={{ marginBottom: 4 }}>
                          {t('Mon - Fri')} {t('8:00 AM - 4:00 PM')}
                        </div>
                      </li>
                      <li>
                        <div className={'footer-top-content-right-working-hours-item-container'}>
                          {t('Sat')} {t('8:00 AM - 3:00 PM')}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={'footer-top-content-right-container'}>
              <div className={'footer-top-content-right-contact-us-container'}>
                <div className={'footer-top-content-title footer-link'} onClick={handleContactUsClick}>{t('CONTACT US')}</div>
                <div className={'footer-top-content-right-contact-us-title'}>
                  <span>{t("Address")}:</span> 4339 Baldwin Ave.El Monte, CA 91731
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  <span>{t('Office Phone')}:</span> (626) 575-1500
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  <span>{t('Market Phone')}:</span> (626) 350-3900
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  <span>{t('Email')}:</span> <a style={{textDecoration: 'underline'}}>info@sunsuntrading.com</a>
                </div>
              </div>
              {/* <div className={'footer-top-content-left-get-in-touch-container'}>
                <div className={'footer-top-content-title footer-top-content-special'}>{t('SOCIAL MEDIAS')}</div>
                <div className={'footer-top-content-left-get-in-touch-list'}>
                  <WechatPopover>
                    <div className={'footer-top-content-left-get-in-touch-item-container'}>
                      <img src={wechatImg} alt="wechat" />
                    </div>
                  </WechatPopover>
                  <div className={'footer-top-content-left-get-in-touch-item-container'}>
                    <img src={instagramImg} alt="instagram" />
                  </div>
                  <div
                    className={'footer-top-content-left-get-in-touch-item-container'}
                    onClick={() => window.open(FACEBOOK_LINK)}>
                    <img src={facebookImg} alt="facebook" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      }
      <div className={'footer-bottom-container'}>
        © 2023 Sun Sun Trading | Powered by Truckxi
      </div>
    </div>
  )
}
