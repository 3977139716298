import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import GetInTouch from '@/components/GetInTouch'
import GoogleMaps from '@/components/GoogleMaps'
import WechatPopover from '@/components/WechatPopover'

import bannerImg from '@/assets/img/contactUs/banner.jpg';
import wechatImg from '@/assets/img/icon/wechat.png';
import instagramImg from '@/assets/img/icon/instagram.png';
import facebookImg from '@/assets/img/icon/facebook.png';
import {FACEBOOK_LINK} from "@/constants";

export default function ContactUs() {
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'contact-us-mobile-container' : 'contact-us-container'
  
  return (
    <Layout isShowFooterTop={false}>
      <div className={rootClassName}>
        <ContentHeader
          className={'contact-us-header-container'}
          des={t('CONTACT US')}
          title={<div className='contact-us-title-special'>{t('Get in Touch with Us: Contact Our Friendly Team Today!')}</div>}
          banner={bannerImg}
        />
        <div className={'contact-us-content-container'}>
          <GetInTouch
            className={'contact-us-content-left-container'}
          />
          <div className={'contact-us-content-right-container'}>
            <div className={'contact-us-content-right-working-hours-container'}>
              <div className={'contact-us-content-right-title contact-us-content-right-title-special'}>{t('WORKING HOURS')}</div>
              <div className={'contact-us-content-right-working-hours-item-container'}>
                <div className={'footer-top-office-business-hour-item-container'}>{t('OFFICE BUSINESS HOURS')}:</div>
                <ul>
                  <li>
                    <div className={'footer-top-content-right-working-hours-item-container'}>
                      {t('Mon - Fri')} {t('8:00 AM - 5:00 PM')}
                    </div>
                  </li>
                </ul>
              </div>
              <div className={'contact-us-content-right-working-hours-item-container'}>
                <div className={'footer-top-office-business-hour-item-container'}>{t('WHOLESALE MARKET HOURS')}:</div>
                  <ul>
                    <li>
                      <div className={'footer-top-content-right-working-hours-item-container'} style={{marginBottom: 4}}>
                        {t('Mon - Fri')} {t('8:00 AM - 4:00 PM')}
                      </div>
                    </li>
                    <li>
                      <div className={'footer-top-content-right-working-hours-item-container'}>
                        {t('Sat')} {t('8:00 AM - 3:00 PM')}
                      </div>
                    </li>
                  </ul>
              </div>
            </div>
            <div className={'contact-us-content-right-contact-us-container'}>
              <div className={'contact-us-content-right-title contact-us-content-right-title-special'}>{t('CONTACT US')}</div>
              <div className={'contact-us-content-right-contact-us-title'}>
                <span>{t("Address")}:</span> 4339 Baldwin Ave.El Monte, CA 91731
              </div>
              <div className={'contact-us-content-right-contact-us-des'}>
                <span>{t('Office Phone')}:</span> (626) 575-1500
              </div>
              <div className={'contact-us-content-right-contact-us-des'}>
                <span>{t('Market Phone')}:</span> (626) 350-3900
              </div>
              <div className={'contact-us-content-right-contact-us-des'}>
                <span>{t('Email')}:</span> <a style={{textDecoration: 'underline'}}>info@sunsuntrading.com</a>
              </div>
            </div>
            {/* <div className={'contact-us-content-right-social-medias-container'}>
              <div className={'contact-us-content-right-title'}>{t('SOCIAL MEDIAS')}</div>
              <div className={'contact-us-content-right-social-medias-list'}>
                <WechatPopover>
                  <div className={'contact-us-content-right-social-medias-item-container'}>
                    <img src={wechatImg} alt="wechat" />
                  </div>
                </WechatPopover>
                <div className={'contact-us-content-right-social-medias-item-container'}>
                  <img src={instagramImg} alt="instagram" />
                </div>
                <div 
                  className={'contact-us-content-right-social-medias-item-container'}
                  onClick={() => window.open(FACEBOOK_LINK)}>
                  <img src={facebookImg} alt="facebook" />
                </div>
              </div>
            </div> */}
            {/* <div className={'contact-us-content-right-map-container'}>
              <GoogleMaps zoomControl={!commonState.isMobile}/>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}
