import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {MinusCircleFilled, PlusCircleFilled} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import noImage from '@/assets/img/icon/noImage.png'
import favoriteDefaultIcon from '@/assets/img/icon/favoriteDefault.png'
import favoriteActiveIcon from '@/assets/img/icon/favoriteActive.png'

import {getFormatMoney} from '@/common';
import {PRODUCT_ITEM_BTN_TYPE_1, PRODUCT_ITEM_BTN_TYPE_2, PRODUCT_ITEM_BTN_TYPE_3} from '@/constants';

const defaultProps = {
  img: noImage,
  largeImg: noImage,
  price: '',
  des: '',
  desChinese: '',
  btnText: 'Add to cart',
  onProductItemClick: () => {},
  onBtnClick: () => {},
  productType: null,
  isShowPrice: true,
  isFormatPriceToMoney: false,
  isFavorited: false,
  isShowFavorite: false,
  onFavoriteIconClick: () => {},
  quantity: 0,
  btnType: PRODUCT_ITEM_BTN_TYPE_1,
  onMinBtnClick: () => {},
  onPlusBtnClick: () => {},
  isPromotion: false,
  stock: 999,
  isOverSaleable: false,
  measureUnit: null,
  packageType: null
}
export default function ProductItem(props) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'product-item-mobile-container' : 'product-item-container'
  
  const {price, isShowPrice, isFormatPriceToMoney} = options
  
  const showFavoriteIcon = options.isFavorited ? favoriteActiveIcon : favoriteDefaultIcon
  
  const handleProductItemClick = () => {
    options.onProductItemClick()
  }
  const handleBtnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    options.onBtnClick()
  }
  const handleFavoriteIconClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    options.onFavoriteIconClick()
  }
  const handleType2BtnContainerClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  if (options.stock <= 0 && !options.isOverSaleable) {
    options.btnType = PRODUCT_ITEM_BTN_TYPE_3
    options.btnText = 'Out of stock'
  }
  const enablePlusBtnClick = options.isOverSaleable || options.quantity + 1 >= options.stock
  
  return (
    <div className={rootClassName} onClick={handleProductItemClick}>
      <div className={'product-item-img-container'}>
        {props.largeImg? <img src={options.largeImg} alt=""/> : (options.img ? <img src={options.img} alt=""/> : <img src={noImage} alt=""/>) }
        {
          options.isShowFavorite &&
          <div
            className={'product-item-favorite-icon-container'}
            onClick={handleFavoriteIconClick}>
            <img src={showFavoriteIcon} alt="" />
          </div>
        }
        {options.isPromotion && <div className="product-item-promotion-icon-container">
          <img src={require('../../assets/img/icon/discountIcon.png')} alt="" />
        </div>}
      </div>
      {
        isShowPrice &&
        <div className={'product-item-price overflow-ellipsis-1'}>
          <span>${isFormatPriceToMoney ? getFormatMoney(price) : price}</span>
          <span>&nbsp;/ {options?.productType === 'Fixed' ? options?.packageType : options?.measureUnit}</span>
        </div>
      }
      <div className={'product-item-des overflow-ellipsis-2'}>
        {localStorage.getItem('LANGUAGE_KEY') === 'zh' ? options.desChinese : options.des} 
      </div>
      {
        options.btnType === PRODUCT_ITEM_BTN_TYPE_1 &&
        <div className={'product-item-btn'} onClick={handleBtnClick}>
          {t(options.btnText)}
        </div>
      }
      {
        options.btnType === PRODUCT_ITEM_BTN_TYPE_2 &&
        <div className={'product-item-type-2-btn-container'} onClick={handleType2BtnContainerClick}>
          <MinusCircleFilled
            className={'product-item-type-2-btn-min-icon'}
            onClick={options.onMinBtnClick}
          />
          <div className={'product-item-type-2-btn-qty'}>{options.quantity}</div>
          <PlusCircleFilled
            className={'product-item-type-2-btn-plus-icon'}
            disabled={!enablePlusBtnClick}
            onClick={() => {
              if (enablePlusBtnClick) {
                options.onPlusBtnClick()
              }
            }}
          />
        </div>
      }
      {
        options.btnType === PRODUCT_ITEM_BTN_TYPE_3 &&
        <div className={'product-item-btn-disabled'}>
          {t(options.btnText)}
        </div>
      }
    </div>
  )
}
