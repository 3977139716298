import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {LeftOutlined} from '@ant-design/icons';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import {addSubCustomerItem} from '@/store/modules/subordinate.slice';
import {TOOLKIT_ASYNC_THUNK_REJECTED_KEY} from '@/constants';

import UserInfoForm from '@/components/UserInfoForm'

// const str = 'olong-scm-004-sub-006'
// const initialValues = {
//   name: `name ${str}`,
//   emailAddress: `${str}@mailinator.com`,
//   phoneNumber: `1234567006`,
//   address: `address${str}`,
//   city: `city${str}`,
//   state: `state${str}`,
//   zipCode: `11354`,
//   password: '123456'
// }

const defaultProps = {
  className: '',
  onBack: () => {},
  editInfo: null
};

export default function MySubordinateDetail(props) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-subordinate-detail-mobile-container' : 'my-subordinate-detail-container'
  
  const {editInfo} = options

  const initialValues = useMemo(() => {
    return {}
  }, [])

  const userInfoFormRef = useRef(null);
  
  const resetFields = () => userInfoFormRef.current?.resetFields()
  
  const handleAddSubCustomer = (values) => {
    const subCustomer = {
      name: values.name,
      email: values.emailAddress,
      phone: values.phoneNumber,
      street: values.address,
      city: values.city,
      state: values.state,
      zipcode: values.zipCode,
      password: values.password
    };
    dispatch(addSubCustomerItem({subCustomer}))
      .then(({meta}) => {
        if (meta.requestStatus === TOOLKIT_ASYNC_THUNK_REJECTED_KEY) {
          return
        }
        resetFields()
      })
  }
  const handleEditSubCustomer = (values) => {
    // const address = {
    //   name: values.name,
    //   email: values.emailAddress,
    //   phone: values.phoneNumber,
    //   street: values.address,
    //   city: values.city,
    //   state: values.state,
    //   zipcode: values.zipCode,
    //   password: values.password
    // };
    // dispatch(updateAddressItem({address, customerDetailId: editInfo.CustomerDetail_id}))
  }
  const onFormFinish = (values) => {
    if (!editInfo) {
      return handleAddSubCustomer(values)
    }
    handleEditSubCustomer(values)
  }
  const handleSaveClick = () => {
    userInfoFormRef.current?.submit()
  }

  useEffect(() => {
    resetFields()
  }, [initialValues])

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      <div className={'my-subordinate-detail-top-container'} onClick={options.onBack}>
        <LeftOutlined />
        <div className={'my-subordinate-detail-top-title'}>{t('Subordinate Detail')}</div>
      </div>
      <UserInfoForm
        ref={userInfoFormRef}
        isShowBaseInfo={true}
        isShowCompanyInfo={false}
        isShowAddressInfo={true}
        isShowPassword={true}
        onFormFinish={onFormFinish}
        initialValues={initialValues}
      />
      <div 
        className={'my-subordinate-detail-btn'}
        onClick={handleSaveClick}>
        {t('Save')}
      </div>
    </div>
  )
}
