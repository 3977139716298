import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'

export default function TermsOfService() {
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'terms-of-service-mobile-container' : 'terms-of-service-container'
  
  return (
    <Layout
      isDetailPage={true}
      detailPageTitle={t('Terms of Service')}>
      <div className={rootClassName}>
        <ContentHeader
          className={'home-header-container'}
          des={t('SUN SUN TRADING')}
          // title={t('OLONG RESTAURANT SUPPLIES')}
          title2={t('TERMS OF SERVICE')}
        />
        <div className={'terms-of-service-content-container'}>
          <div>
            These Terms of Service ("Agreement") govern your use of [Your Company Name]'s services, website, mobile application, or any other digital platform (collectively referred to as the "Service"). By accessing or using the Service, you agree to be bound by this Agreement.
          </div>
          <div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>1. </span>
              Acceptance of Terms: By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by this Agreement. If you do not agree to these terms, you may not access or use the Service.
            </div>
            <div>
              <div className={'terms-of-service-content-list-item-container'}>
                <span>2. </span>
                Use of the Service: a. Eligibility: You must be at least 18 years old or the legal age of majority in your jurisdiction to use the Service. b. User Account: To access certain features or areas of the Service, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account. c. Prohibited Activities: You agree not to engage in any activity that may:
              </div>
              <div className={'terms-of-service-content-list-2-container'}>
                <div>
                  Violate any applicable laws, regulations, or third-party rights.
                </div>
                <div>
                  Interfere with the proper functioning of the Service.
                </div>
                <div>
                  Attempt to gain unauthorized access to the Service or any other user accounts.
                </div>
                <div>
                  Use the Service to transmit any harmful or malicious code or engage in any activity that could damage, disable, or impair the Service.
                </div>
              </div>
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>3. </span>
              Intellectual Property: All intellectual property rights in the Service (including but not limited to copyrights, trademarks, logos, and software) are owned by [Your Company Name] or its licensors. You may not copy, modify, distribute, transmit, display, reproduce, or create derivative works based upon the Service or any part thereof without prior written consent.
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>4. </span>
              Privacy: Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information when you use our Service.
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>5. </span>
              Disclaimer of Warranties: The Service is provided on an "as-is" and "as available" basis. We do not warrant that the Service will be error-free, uninterrupted, or free of viruses or other harmful components. Your use of the Service is at your own risk.
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>6. </span>
              Limitation of Liability: In no event shall [Your Company Name] or its directors, officers, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service.
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>7. </span>
              Changes to the Agreement: We reserve the right to modify or update this Agreement at any time. We will notify you of any material changes through the Service or by other means. Your continued use of the Service after the effective date of the revised Agreement constitutes your acceptance of the changes.
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>8. </span>
              Governing Law and Jurisdiction: This Agreement shall be governed by and construed in accordance with the laws of [your jurisdiction]. Any disputes arising out of or in connection with this Agreement shall be resolved in the courts of [your jurisdiction].
            </div>
            <div className={'terms-of-service-content-list-item-container'}>
              <span>9. </span>
              Entire Agreement: This Agreement constitutes the entire agreement between you and [Your Company Name] regarding your use of the Service and supersedes any prior or contemporaneous agreements or understandings.
            </div>
          </div>
          <div>If you have any questions or concerns regarding this Agreement, please contact us at [contact email].</div>
          <div>This Terms of Service agreement was last updated on [date].</div>
        </div>
      </div>
    </Layout>
  )
}
