import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Form, Input, message} from 'antd'

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import {seedContactInfo} from '@/api/req-api'

const defaultProps = {
  className: '',
  onSendSuccess: () => {},
};

const GetInTouch = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'get-in-touch-mobile-container' : 'get-in-touch-container'

  const formRef = useRef(null);

  const resetFields = () => formRef.current?.resetFields()
  const onFormFinish = (values) => {
    const params = {
      Name: values.name,
      Email: values.emailAddress,
      PhoneNumber: values.phoneNumber,
      Message: values.message
    }
    seedContactInfo(params).then(() => {
      resetFields()
      message.success(t('Sent successfully'))
      options.onSendSuccess(values)
    })
  }
  const handleSendClick = () => {
    formRef.current?.submit()
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  useImperativeHandle(ref, () => ({
    resetFields,
  }))

  return (
    <div className={className}>
      <div className={'get-in-touch-title'}>
        {t('GET IN TOUCH')}
      </div>
      <Form
        className={'get-in-touch-form-container'}
        ref={formRef}
        layout={'vertical'}
        requiredMark={false}
        onFinish={onFormFinish}
        autoComplete="off">
        <Form.Item
          label={t('Name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('Please enter your name'),
            }
          ]}
        >
          <Input
            placeholder={t('Enter your name')}
          />
        </Form.Item>
        <Form.Item
          label={t('Email Address')}
          name="emailAddress"
          rules={[
            {
              required: true,
              message: t('Please enter your email address'),
            },
            {
              type: 'email',
              message: t('The enter is not valid Email Address'),
            }
          ]}>
          <Input
            placeholder={t('Enter your email address')}
          />
        </Form.Item>
        <Form.Item
          label={t('Phone number')}
          name="phoneNumber">
          <Input
            placeholder={t('Enter your phone number')}
          />
        </Form.Item>
        <Form.Item
          label={t('Message')}
          name="message"
          rules={[
            {
              required: true,
              message: t('Please enter your message'),
            }
          ]}
        >
          <Input.TextArea
            autoSize={{ minRows: 9, maxRows: 5 }}
          />
        </Form.Item>
      </Form>
      <div className={'get-in-touch-btn'} onClick={handleSendClick}>
        {t('Send')}
      </div>
    </div>
  )
})

export default GetInTouch
