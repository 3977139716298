import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {DatePicker, Empty, Modal} from 'antd';
import {FullscreenOutlined, FullscreenExitOutlined} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import {exitFullscreen, getFormatDate, getUsingClassName, isFullScreen, requestFullScreen} from '@/common';
import {DATE_FORMAT_3, DATE_FORMAT_4} from '@/constants';
import {getBalanceDetail} from '@/api/req-api'
import moment from "moment";

const defaultProps = {
  className: '',
};

const BalanceDetail = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'balance-detail-mobile-container' : 'balance-detail-container'

  const {userInfo} = commonState
  const customerId = userInfo?.Customer_id
  const [rangePickerVal, setRangePickerVal] = useState([moment().startOf('year'), moment()])
  const [pdfBlob, setPdfBlob] = useState(null)
  const [PdfFrameLoaded, setPdfFrameLoaded] = useState(false)
  
  const rangePickerRootRef = useRef(null)
  const contentRef = useRef(null)
  const [isFullScreenStatus, setIsFullScreenStatus] = useState(isFullScreen())

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const handleRangePickerChange = (dates, dateStrings) => {
    setRangePickerVal(dates)
  }

  useEffect(() => {
    if (customerId) {
      const startTime = getFormatDate(rangePickerVal?.[0]?.utc(), DATE_FORMAT_4)
      const endTime = getFormatDate(rangePickerVal?.[1]?.utc(), DATE_FORMAT_4)
      if (!startTime || !endTime) {
        return setPdfBlob(null)
      }
      getBalanceDetail(customerId, startTime, endTime).then(res => {
        setPdfBlob(res.data)
      }).catch(() => {})
    }
  }, [customerId, rangePickerVal])
  
  const handleFullscreenControlClick = () => {
    if (isFullScreenStatus) {
      setIsFullScreenStatus(false)
      return exitFullscreen()
    }
    setIsFullScreenStatus(true)
    requestFullScreen(contentRef.current)
  }
  const iframeId = 'Pdf'
  const iframeIsLoaded = () => {
    if (!PdfFrameLoaded) {
      const pdfFrame = window.frames[iframeId];
      pdfFrame.focus();
      pdfFrame.print();
      setPdfFrameLoaded(true)
    }
  }
  
  useEffect(() => {
    setPdfFrameLoaded(false)
  }, [pdfBlob])

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  
  const renderIFrame = () => {
    if (!pdfBlob) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    const file = window.URL.createObjectURL(pdfBlob);
    return (
      <iframe onLoad={iframeIsLoaded} id={iframeId} name={iframeId} src={file} />
    );
  }
  
  return (
    <Modal
      className={className}
      maskClosable={false}
      keyboard={false}
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className={'balance-detail-title'}>{t('Balance Detail')}</div>
      <div className={'balance-detail-content-container'} ref={contentRef}>
        <div className={'balance-detail-content-top-container'}>
          <div ref={rangePickerRootRef}>
            <DatePicker.RangePicker
              className={'balance-detail-range-picker'}
              value={rangePickerVal}
              format={DATE_FORMAT_3}
              onChange={handleRangePickerChange}
              getPopupContainer={() => rangePickerRootRef.current}
              showTime
              placeholder={[t('Start date'), t('End date')]}
            />
          </div>
          <div
            className={'balance-detail-content-fullscreen-control-container'}
            onClick={handleFullscreenControlClick}>
            {
              isFullScreenStatus ? <FullscreenExitOutlined /> : <FullscreenOutlined />
            }
          </div>
        </div>
        <div className={'balance-detail-spacing'}></div>
        <div className={'balance-detail-iframe-container'}>
          {renderIFrame()}
        </div>
      </div>
    </Modal>
  )
})

export default BalanceDetail
